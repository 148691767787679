import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search';
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components';

const MainWrapper = styled.div`margin: 0;`;

const faqMeta1 = {
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	mainEntity: {
		'@type': 'Question',
		name: 'How Much Does A New Website Cost?',
		acceptedAnswer: {
			'@type': 'Answer',
			text:
				'There is not a single answer to this because website design is based on individual needs. Every site is unique and requires a different component, so the cost of a website is based on your specifications. However, web design can cost between $3000 and $5000.'
		}
	}
};

const faqMeta2 = {
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	mainEntity: {
		'@type': 'Question',
		name: 'How Long Will It Take To Get A New Website?',
		acceptedAnswer: {
			'@type': 'Answer',
			text:
				'On average, it takes six to eight weeks, but the pace can be set by each client. Your availability and how soon you give feedback will also determine how fast you can get your site. Codestaff will do its best to deliver your website as soon as possible.'
		}
	}
};

const WebDesigner = () => (
	<MainWrapper>
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
			<script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
		</Helmet>
		<main>
			<section className="cx_catagories">
				<div className="container-fluid cx_top">
					<div className="container">
						<div className="row">
							<div className="col-md-8 col-sm-10">
								<h2>
									Hire the top freelance<br />
									<span> web designers.</span>
								</h2>
								<p>
									Codestaff is a marketplace for web designers. Top companies and start-ups choose
									Codestaff professional web designers for their mission critical software projects.
								</p>
								<Link to="/hire/" className="skills-banner-btn">
									Hire a top web designer now
									<StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
								</Link>
							</div>
						</div>
					</div>
				</div>
				<CxSearch
					title="HIRE FREELANCE WEB DESIGNERS"
					banner="/web-designer.png"
					bannerAlt="web designer banner"
				/>
			</section>
			<HireAndApply />
			<TopTrendingSkills />
		</main>
	</MainWrapper>
);

export default WebDesigner;
