import React from 'react'
import Layout from '../components/layout'
import WebDesigner from '../components/categories/web-designer'
import Seo from '../components/seo'

const WebDesignerPage = ({ location }) => {
  return (
    <Layout location={location}>
      <WebDesigner />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Web Designer Freelancers | Codestaff'
  const description =
    'Hire the best Web Designer freelancers at Codestaff. Get the top 1% of Web Designer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default WebDesignerPage
